const SEO = [
	{
		page: "home",
		description:
			"I'm a neural networks engineer specialized in computer vision. I work on projects involving medical image analysis, financial forecasting, and embedded AI systems.",
		keywords: ["Matheus", "Matheus Parracho", "Neural Networks Engineer", "Computer Vision"],
	},

	{
		page: "about",
		description:
			"I'm a neural networks engineer with a background in Electronic and Computer Engineering (UFRJ) and a Master's in Computer Vision (Telecom SudParis). My expertise includes deep learning for medical imaging, financial time-series prediction, and embedded AI development.",
		keywords: ["Matheus Parracho", "UFRJ", "Telecom SudParis", "Deep Learning", "Computer Vision"],
	},

	{
		page: "articles",
		description:
			"A curated collection of my thoughts on neural networks, contrastive learning, variational autoencoders, and AI applications in finance and healthcare.",
		keywords: ["Matheus Parracho", "Siamese Networks", "VAE", "Financial AI", "Medical Imaging"],
	},

	{
		page: "projects",
		description:
			"My projects range from brain tumor segmentation with Transformers (UNETR), signature verification using Siamese Networks, to time-series forecasting with TCNs and WaveNets. I also explore embedded implementations of neural networks.",
		keywords: ["Matheus Parracho", "UNETR", "TCN", "Siamese Network", "Embedded AI", "Computer Vision Projects"],
	},

	{
		page: "contact",
		description:
			"If you're working on innovative projects in computer vision, AI for healthcare or finance, or embedded neural networks, let's connect!",
		keywords: ["Matheus", "Matheus Parracho", "Contact", "AI Collaboration", "Deep Learning"],
	},
];

export default SEO;
